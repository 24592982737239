import React, { useMemo, useState } from "react";
import {
  ActionIcon,
  Button,
  Center,
  Loader,
  Navbar,
  Popover,
  ScrollArea,
  Text,
  TextInput,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useParams } from "react-router-dom";

import { showNotification } from "@mantine/notifications";
import { useForm, yupResolver } from "@mantine/form";
import MDListItem from "./MDListItem";
import { useFetchMDDatabasesQuery } from "../../../hooks/md-databases/query/useFetchMDDatabases.query";
import { useAddDbMutation } from "../../../hooks/md-databases/mutation/useAddDb.mutation";

import { initialValues } from "../../../initial-values";
import { validations } from "../../../validations";

interface IMDSidebar {
  opened: boolean;
}

const MDSidebar = (props: IMDSidebar) => {
  const { opened: drawerOpened } = props;
  const [opened, setOpened] = useState(false);
  const { instanceId } = useParams<{ instanceId: string }>();

  const { data, isLoading, isFetching, refetch } = useFetchMDDatabasesQuery({
    _id: instanceId ?? "",
  });

  const databases: TMDDatabases[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  const { mutateAsync, isPending } = useAddDbMutation();

  const { onSubmit, reset, getInputProps } = useForm({
    initialValues: initialValues.addDb,
    validate: yupResolver(validations.addDb),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleSubmit = async (values: typeof initialValues.addDb) => {
    const res = await mutateAsync({
      instanceId: instanceId ?? "",
      ...values,
    });
    if (res.status === "success") {
      refetch();
      reset();

      setOpened(false);
      showNotification({
        message: res.message,
        color: "green",
      });
    } else {
      showNotification({
        message: res.data.message,
        color: "red",
      });
    }
  };

  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      hidden={!drawerOpened}
      width={{ sm: 200, lg: 250 }}
    >
      <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
        {isLoading && (
          <Center mih={"80vh"}>
            <Loader size={"sm"} />
          </Center>
        )}
        {!isLoading &&
          databases.map((db) => <MDListItem data={db} key={db.name} />)}
        {!isLoading && databases.length === 0 && (
          <Center mih={"80vh"}>
            <Text size={"sm"}>Click "+ Add" to DB</Text>
          </Center>
        )}
      </Navbar.Section>
      <Popover
        opened={opened}
        onChange={setOpened}
        width={300}
        trapFocus
        position="bottom"
        withArrow
        shadow="md"
      >
        <Popover.Target>
          <ActionIcon
            onClick={() => setOpened((o) => !o)}
            variant="filled"
            color="blue"
            size={"lg"}
            radius={50}
            style={{ alignSelf: "flex-end", position: "absolute", bottom: 20 }}
          >
            <IconPlus />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown
          sx={(theme) => ({
            background:
              theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
          })}
        >
          <form onSubmit={onSubmit(handleSubmit)}>
            <TextInput
              label="Database name"
              placeholder="Enter database name"
              size="xs"
              {...getInputProps("name")}
            />
            <TextInput
              my={10}
              label="Collection name"
              placeholder="Enter collection name"
              size="xs"
              {...getInputProps("collectionName")}
            />
            <Button
              disabled={isPending || isFetching}
              loading={isPending || isFetching}
              my={10}
              size="xs"
              type="submit"
            >
              Create
            </Button>
          </form>
        </Popover.Dropdown>
      </Popover>
    </Navbar>
  );
};

export default MDSidebar;
