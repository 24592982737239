import { useQuery } from "@tanstack/react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";

const get = async (params: { mongoInstanceId: string; dbName: string }) => {
  const response: TServerResponse = await request({
    url: apiUrls.database.FETCH_DB_USERS,
    method: "GET",
    params: {
      ...params,
    },
  });

  return response;
};

export const useFetchDbUsersQuery = (params: {
  mongoInstanceId: string;
  dbName: string;
}) => {
  return useQuery({
    queryKey: ["admin", apiUrls.database.FETCH_DB_USERS, params],
    queryFn: () => get(params),
  });
};
