import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EDrawerActions } from "../../../components/action-drawer/actions.enum";

type TDrawerAction = keyof typeof EDrawerActions;

interface IActionDrawer {
  opened: boolean;
  drawerActions: TDrawerAction;
}

const initialState: IActionDrawer = {
  opened: false,
  drawerActions: "ADD_DB_INSTANCE",
};

export const drawerSlice = createSlice({
  name: "action-drawer",
  initialState,
  reducers: {
    showDrawer: (state, action: PayloadAction<{ action: TDrawerAction }>) => {
      state.opened = true;
      state.drawerActions = action.payload.action;
    },
    toggleDrawer: (state) => {
      state.opened = !state.opened;
    },
  },
});

export const { showDrawer, toggleDrawer } = drawerSlice.actions;

export default drawerSlice.reducer;
