import { ActionIcon, CopyButton, Flex, Text } from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";

export const BotColumns: TTableColumns[] = [
  { key: "title", label: "Title" },
  {
    key: "accessKey",
    label: "Access Key",
    renderCell: (data) => (
      <Flex align={"center"}>
        <Text>{data.accessKey}</Text>
        <CopyButton value={data.accessKey} timeout={3000}>
          {({ copied, copy }) => (
            <ActionIcon p={0} color="cyan" ml={5} size={"sm"} onClick={copy}>
              {copied ? <IconCheck color="green" /> : <IconCopy />}
            </ActionIcon>
          )}
        </CopyButton>
      </Flex>
    ),
  },
];
