export const adminPrefix = "/admin/";

export const CONSTANTS = {
  PAGE_LIMIT: 10,
};

export const REG_EX = {
  MONGO_URI: /mongodb\+srv:\/\/(?:[^:]+:[^@]+@)?([^]+)\/([^?]+)(?:\?[^#]+)?/,
};

export const PROJECT_STATUS = [
  {
    label: "New",
    value: "new",
  },
  {
    label: "Open",
    value: "open",
  },
  {
    label: "Waiting",
    value: "waiting",
  },
  {
    label: "Closed",
    value: "closed",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
];

export const DB_USERS_PRIVILEGES = [
  { value: "read", label: "Read" },
  { value: "readWrite", label: "Read and write" },
  { value: "dbAdmin", label: "Database administration" },
  { value: "userAdmin", label: "User administration" },
  { value: "dbOwner", label: "Database owner" },
  { value: "readAnyDatabase", label: "Read any database" },
  { value: "readWriteAnyDatabase", label: "Read and write any database" },
  {
    value: "userAdminAnyDatabase",
    label: "User administration on any database",
  },
  {
    value: "dbAdminAnyDatabase",
    label: "Database administration on any database",
  },
  { value: "root", label: "Superuser (root)" },
];
