import React from "react";
import ContentBlock from "../../components/ContentBlock/ContentBlock";
import { useParams } from "react-router-dom";
import { Tabs } from "@mantine/core";
import { IconCirclesRelation, IconUserShield } from "@tabler/icons-react";
import DatabaseUsers from "./components/DatabaseUsers";
import DbCollections from "./components/DbCollections";

const DatabaseDetails = () => {
  const { dbName } = useParams<{ dbName: string }>();

  return (
    <ContentBlock
      loading={false}
      title={dbName?.toLocaleUpperCase() ?? ""}
      showBack
      backEventPath="/mongodb-instances"
    >
      <Tabs defaultValue="users">
        <Tabs.List>
          <Tabs.Tab mr={10} value="users" icon={<IconUserShield size="1rem" />}>
            Users
          </Tabs.Tab>
          <Tabs.Tab
            mx={10}
            value="collections"
            icon={<IconCirclesRelation size="1rem" />}
          >
            Collections
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="users" pt="xs">
          <DatabaseUsers />
        </Tabs.Panel>

        <Tabs.Panel value="collections" pt="xs">
          <DbCollections />
        </Tabs.Panel>
      </Tabs>
    </ContentBlock>
  );
};

export default DatabaseDetails;
