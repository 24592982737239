import { Area } from "react-easy-crop";

const createImage = (url: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
};

async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
  imageExtension: File
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  if (ctx !== null) {
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );
  }

  return canvas.toDataURL(imageExtension.type);
}

export const cropImage = async (
  extension: File,
  image: string,
  croppedAreaPixels: Area,
  onError: Function
) => {
  try {
    const croppedImage = await getCroppedImg(
      image,
      croppedAreaPixels,
      extension
    );

    return await fetch(croppedImage).then((res) => res.blob());
  } catch (err) {
    onError(err);
  }
};
