import { Box, Button, Grid, TextInput } from "@mantine/core";
import React, { memo } from "react";
import DrawerHeader from "../components/DrawerHeader";
import { useForm, yupResolver } from "@mantine/form";
import { initialValues } from "../../../initial-values";
import { validations } from "../../../validations";

import { showNotification } from "@mantine/notifications";
import { useAppDispatch } from "../../../store/hooks";
import { toggleDrawer } from "../../../store/reducers/action-drawer/action-drawer-reducer";
import { useCreateDBInstanceMutation } from "../../../hooks/md-instance/mutation/useCreateDBInstance.mutation";

const AddDBInstance = () => {
  const dispatch = useAppDispatch();

  const { isPending, mutateAsync, reset } = useCreateDBInstanceMutation();
  const { getInputProps, onSubmit } = useForm({
    initialValues: initialValues.database,
    validate: yupResolver(validations.db),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleSubmit = async (values: TDatabase) => {
    const res = await mutateAsync(values);
    if (res.status === "success") {
      dispatch(toggleDrawer());
      reset();
      showNotification({
        message: res.message,
        color: "green",
      });
    } else {
      showNotification({
        message: res.data.message,
        color: "red",
      });
    }
  };
  return (
    <Box>
      <DrawerHeader title="Add Instance" />

      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid my={10} gutter={10}>
          <Grid.Col lg={12}>
            <TextInput {...getInputProps("name")} label="Name" />
          </Grid.Col>
          <Grid.Col lg={12}>
            <TextInput {...getInputProps("scheme")} label="Scheme" />
          </Grid.Col>
          <Grid.Col lg={12}>
            <TextInput {...getInputProps("host")} label="Host" />
          </Grid.Col>
          <Grid.Col lg={12}>
            <TextInput {...getInputProps("username")} label="Username" />
          </Grid.Col>
          <Grid.Col lg={12}>
            <TextInput
              type="password"
              {...getInputProps("password")}
              label="Password"
            />
          </Grid.Col>
          <Grid.Col lg={12}>
            <Button
              loading={isPending}
              mt={10}
              type="submit"
              disabled={isPending}
            >
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Box>
  );
};

export default memo(AddDBInstance);
