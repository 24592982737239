import { useQuery } from "@tanstack/react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";

interface IParams {
  _id: string;
}

const get = async (params: IParams) => {
  const response: TServerResponse = await request({
    url: apiUrls.document.GET_DOCUMENT_DETAILS,
    method: "GET",
    params: {
      ...params,
    },
  });

  return response;
};

export const useDocumentDetailsQuery = (params: IParams) => {
  return useQuery({
    queryKey: ["admin", "documents/get-details", params._id],
    queryFn: () => get(params),
    enabled: Boolean(params._id),
  });
};
