import { createStyles } from "@mantine/core";

export const styles = createStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#f8f9fd !important",
  },
  container: {
    backgroundColor: "#fff",
    padding: "40px",
    paddingBottom: "3.5em",
    boxShadow: " 0px 10px 34px -15px rgb(0 0 0 / 24%)",
    position: "relative",
    borderRadius: "10px",
    width: 450,
  },
  userIcon: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff !important",
    borderRadius: "50px",
    width: "50px",
    height: "50px",
  },
  title: {
    color: theme.colors.primary,
    textAlign: "center",
    fontSize: 22,
    fontWeight: 400,
    margin: "10px 0px",
  },
  inputUserName: {
    backgroundColor: "#f2f2f2",
    color: "#00000036",
    padding: "10px 12px",
    width: "100%",
    borderStyle: "none",
    marginBottom: 15,
    outline: "none",
    borderRadius: "5px",
  },
  btn: {
    width: "60%",
    fontWeight: "bold",
    margin: "auto",
    height: 40,
    position: "absolute",
    bottom: -18,
    left: 0,
    right: 0,
  },
}));
