import React, { useMemo } from "react";
import ContentBlock from "../../components/ContentBlock/ContentBlock";
import { Grid, Paper, Text } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchMDDatabasesQuery } from "../../hooks/md-databases/query/useFetchMDDatabases.query";

const MDDatabases = () => {
  const { instanceId } = useParams<{ instanceId: string }>();
  const navigate = useNavigate();

  const { data, isLoading } = useFetchMDDatabasesQuery({
    _id: instanceId ?? "",
  });

  const databases: TMDDatabases[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);
  return (
    <ContentBlock
      title="Databases"
      loading={isLoading}
      emptyData={databases.length === 0}
      showBack
    >
      <Grid>
        {databases.map((db) => (
          <Grid.Col lg={3} key={db.name}>
            <Paper
              onClick={() =>
                navigate(
                  `/mongodb-instances/databases/${instanceId}/${db.name}`
                )
              }
              shadow="sm"
              p={10}
              style={{ cursor: "pointer", borderTop: "3px solid green" }}
            >
              <Text size={"sm"} fw={"bold"}>
                {db.name}
              </Text>
              <Text size={10} fw={"bold"} color="dimmed" my={10}>
                Collections : {db.collections}
              </Text>
            </Paper>
          </Grid.Col>
        ))}
      </Grid>
    </ContentBlock>
  );
};

export default MDDatabases;
