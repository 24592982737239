import { Button, Grid, Modal, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React, { FC, memo } from "react";

import { validations } from "../../../validations";
import { useEditDBInstanceMutation } from "../../../hooks/md-instance/mutation/useEditDBInstance.mutation";

interface IEditDbInstanceModal {
  show: boolean;
  closeModal: () => void;
  data: TDatabase;
  refetch: () => void;
}

const EditDbInstanceModal: FC<IEditDbInstanceModal> = ({
  closeModal,
  data,
  show,
  refetch,
}) => {
  const { isPending, mutateAsync } = useEditDBInstanceMutation();
  const { getInputProps, onSubmit } = useForm({
    initialValues: data,
    validate: yupResolver(validations.db),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleSubmit = async (values: TDatabase) => {
    const res = await mutateAsync(values);
    if (res.status === "success") {
      refetch();
      closeModal();

      showNotification({
        message: res.message,
        color: "green",
      });
    } else {
      showNotification({
        message: res.data.message,
        color: "red",
      });
    }
  };
  return (
    <Modal size={"md"} opened={show} onClose={closeModal} title="Edit Instance">
      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid my={10} gutter={10}>
          <Grid.Col lg={12}>
            <TextInput {...getInputProps("name")} label="Name" />
          </Grid.Col>
          <Grid.Col lg={12}>
            <TextInput {...getInputProps("scheme")} label="Scheme" />
          </Grid.Col>
          <Grid.Col lg={12}>
            <TextInput {...getInputProps("host")} label="Host" />
          </Grid.Col>
          <Grid.Col lg={12}>
            <TextInput {...getInputProps("username")} label="Username" />
          </Grid.Col>
          <Grid.Col lg={12}>
            <TextInput
              type="password"
              {...getInputProps("password")}
              label="Password"
            />
          </Grid.Col>
          <Grid.Col lg={12}>
            <Button
              loading={isPending}
              mt={10}
              type="submit"
              disabled={isPending}
            >
              Update
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};

export default memo(EditDbInstanceModal);
