import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";

const create = async (data: TPortainer) => {
  const response: TServerResponse = await request({
    url: apiUrls.portainer.CREATE_PORTAINER,
    method: "POST",
    data,
  });
  return response;
};

export const useCreatePortainerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: create,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["admin", "portainers"] });
    },
  });
};
