import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";

const create = async (data: TDatabase) => {
  const response: TServerResponse = await request({
    url: apiUrls.database.CREATE_DB_INSTANCE,
    method: "POST",
    data,
  });
  return response;
};

export const useCreateDBInstanceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: create,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["admin", apiUrls.database.FETCH_DB_INSTANCES],
      });
    },
  });
};
