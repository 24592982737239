import { botIniValues } from "./bots/bots.values";
import { addDbUserIniValues } from "./db-user/values";
import { addDbIniValues } from "./db/addDB.values";
import { dbIniValues } from "./db/db.values";
import { documentIniValue } from "./documents/document.values";
import { loginInitialValues } from "./login/login.values";
import { portainerIniValues } from "./portainer/portainer.values";
import { projectIniValues } from "./projects/projects.values";

export const initialValues = {
  login: loginInitialValues,
  database: dbIniValues,
  addDb: addDbIniValues,
  telegramBot: botIniValues,
  project: projectIniValues,
  portainer: portainerIniValues,
  document: documentIniValue,
  addDbUser: addDbUserIniValues,
};
