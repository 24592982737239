import React, { useMemo, useState } from "react";
import ContentBlock from "../../components/ContentBlock/ContentBlock";
import DefaultTable from "../../components/table/DefaultTable";
import { COLUMNS } from "../../columns";

import { CONSTANTS } from "../../constants";
import { ActionIcon, Button, Group } from "@mantine/core";
import { IconEdit, IconFileDescription, IconPlus } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useProjectsQuery } from "../../hooks/projects/query/useProjects.query";

const Projects = () => {
  const tableColumns = [...COLUMNS.PROJECTS];
  const [pagedData, setPagedData] = useState({ total: 0 });
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const { data, isLoading } = useProjectsQuery({
    itemPerPage: CONSTANTS.PAGE_LIMIT,
    page,
    search: search,
  });

  const tableData: TProject[] = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      data.pageData && setPagedData(data.pageData);
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  tableColumns.push(
    {
      label: "Documents",
      key: "documents",
      renderCell: (row) => (
        <ActionIcon
          onClick={() => navigate(`/documents/${row._id}`)}
          size={"sm"}
          color="blue"
        >
          <IconFileDescription />
        </ActionIcon>
      ),
    },
    {
      label: "Actions",
      key: "actions",
      renderCell: (row) => (
        <Group>
          <ActionIcon
            onClick={() => navigate(`/projects/edit/${row._id}`)}
            size={"sm"}
            color="cyan"
          >
            <IconEdit />
          </ActionIcon>
        </Group>
      ),
    }
  );

  return (
    <ContentBlock
      loading={false}
      title="Projects"
      rightComponent={
        <Button
          onClick={() => navigate("/projects/create-Project")}
          size="xs"
          leftIcon={<IconPlus size={18} />}
        >
          Add Project
        </Button>
      }
    >
      <DefaultTable
        onChangeSearch={setSearch}
        columns={tableColumns}
        data={tableData}
        isLoading={false}
        paginationProps={{ totalPages: pagedData.total, setPage: setPage }}
      />
    </ContentBlock>
  );
};

export default Projects;
