import { ActionIcon, Flex, Title, Tooltip } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import React, { FC, memo } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { toggleDrawer } from "../../../store/reducers/action-drawer/action-drawer-reducer";

interface TDrawerHeader {
  title: string;
}

const DrawerHeader: FC<TDrawerHeader> = ({ title }) => {
  const dispatch = useAppDispatch();
  return (
    <Flex
      align={"center"}
      justify={"space-between"}
      style={{
        borderBottom: "0.5px solid #e7e7e7",
        margin: "0px -16px",
        padding: "0px 15px",
        paddingBottom: 10,
      }}
    >
      <Title order={5} fw={500}>
        {title}
      </Title>
      <Tooltip label="Close">
        <ActionIcon color="red" onClick={() => dispatch(toggleDrawer())}>
          <IconX />
        </ActionIcon>
      </Tooltip>
    </Flex>
  );
};

export default memo(DrawerHeader);
