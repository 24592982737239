import React, { memo, useMemo, useState } from "react";
import { Box, Button } from "@mantine/core";
import { useFetchDbUsersQuery } from "../../../hooks/md-databases/query/useFetchDbUsers.query";
import { useParams } from "react-router-dom";
import DefaultTable from "../../../components/table/DefaultTable";
import { COLUMNS } from "../../../columns";
import CreateDatabaseUser from "./CreateDatabaseUser";

const DatabaseUsers = () => {
  const tableColumns = [...COLUMNS.DB_USERS];
  const { dbName, instanceId } = useParams<{
    dbName: string;
    instanceId: string;
  }>();

  const { data, isLoading, refetch } = useFetchDbUsersQuery({
    mongoInstanceId: instanceId ?? "",
    dbName: dbName ?? "",
  });

  const [userModal, setUserModal] = useState(false);

  const users: TDBUsers[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <Box>
      <DefaultTable
        columns={tableColumns}
        data={users}
        isLoading={false}
        rightComponent={
          <Button onClick={() => setUserModal(true)}>+ Add User</Button>
        }
      />
      {userModal && (
        <CreateDatabaseUser
          reloadData={refetch}
          onClose={() => setUserModal(false)}
          opened={userModal}
        />
      )}
    </Box>
  );
};

export default memo(DatabaseUsers);
