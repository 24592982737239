import { MantineThemeOverride } from "@mantine/core";

export const customTheme: MantineThemeOverride = {
  // colors: {
  //   primary: [
  //     "#7AD1DD",
  //     "#5FCCDB",
  //     "#44CADC",
  //     "#2AC9DE",
  //     "#1AC2D9",
  //     "#11B7CD",
  //     "#09ADC3",
  //   ],
  // },
  // primaryColor: "primary",
};
