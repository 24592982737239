import { ActionIcon, Center, rem } from "@mantine/core";
import { IconFile, IconX } from "@tabler/icons-react";

export const FileItem = ({
  file,
  onRemove,
}: {
  file: File | null;
  onRemove: () => void;
}) => {
  return (
    <Center
      inline
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[7]
            : theme.colors.gray[1],
        fontSize: theme.fontSizes.xs,
        padding: `${rem(3)} ${rem(7)}`,
        borderRadius: theme.radius.sm,
      })}
    >
      <IconFile size={rem(14)} style={{ marginRight: rem(5) }} />
      <span
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          maxWidth: rem(200),
          display: "inline-block",
        }}
      >
        {file?.name}
      </span>
      <ActionIcon onClick={onRemove} style={{ marginLeft: rem(5) }}>
        <IconX size={rem(14)} />
      </ActionIcon>
    </Center>
  );
};
