import { Avatar, Box, Button, Center, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { IconLock, IconUser } from "@tabler/icons-react";
import { useCallback, useEffect } from "react";
import { useLoginMutation } from "../../hooks/auth/useLoginMutation";
import {
  ILoginFormValues,
  loginInitialValues,
} from "../../initial-values/login/login.values";
import { loginValidation } from "../../validations/login/login.validation";
import { styles } from "./Login.styles";
import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { classes } = styles();
  const { mutateAsync, isPending: isLoading } = useLoginMutation();
  const navigate = useNavigate();
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();

  const formHandler = useForm({
    initialValues: loginInitialValues,
    validate: yupResolver(loginValidation),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleLogin = useCallback(
    async (values: ILoginFormValues) => {
      const res = await mutateAsync(values);
      if (
        res.status === "success" &&
        signIn({
          token: res.data.token,
          expiresIn: res.data.expiresIn,
          tokenType: "Bearer",
          authState: res.data.authUserState,
        })
      ) {
        formHandler.reset();
        navigate("/");
      } else {
        console.log("not Login");
        showNotification({
          message: res.data.message,
          color: "red",
        });
      }
    },
    [mutateAsync, formHandler, signIn, navigate]
  );

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <Center className={classes.mainContainer} sx={{ minHeight: "100vh" }}>
      <Box className={classes.container}>
        <Box>
          <Box sx={{ textAlign: "center" }}>
            <Avatar
              color="ocean-blue"
              radius="sm"
              className={classes.userIcon}
              sx={{ textAlign: "center" }}
            >
              <IconUser size={30} color="#fff" />
            </Avatar>
          </Box>
          <h1 className={classes.title}>MS Internal</h1>

          <form onSubmit={formHandler.onSubmit(handleLogin)}>
            <Box my={15}>
              <TextInput
                icon={<IconUser size={16} />}
                withAsterisk
                type={"email"}
                placeholder="Username"
                {...formHandler.getInputProps("username")}
              />
            </Box>
            <Box my={15}>
              <TextInput
                icon={<IconLock size={16} />}
                withAsterisk
                type={"password"}
                placeholder="Password"
                {...formHandler.getInputProps("password")}
              />
            </Box>

            <Box>
              <Button
                loading={isLoading}
                disabled={isLoading}
                type="submit"
                className={classes.btn}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Center>
  );
};

export default Login;
