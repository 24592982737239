import { createBrowserRouter } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import ErrorPage from "../pages/error/ErrorPage";
import Login from "../pages/login/Login";
import ProtectedRoute from "../routers/Protected.route";

import TelegramBots from "../pages/telegram-bots/TelegramBots";
import Projects from "../pages/projects/Projects";
import CreateProject from "../pages/projects/pages/create-project/CreateProject";
import EditProject from "../pages/projects/pages/edit-project/EditProject";
import Portainers from "../pages/portainers/Portainers";
import ProjectDocuments from "../pages/project-documents/ProjectDocuments";
import DocumentsLayout from "../pages/project-documents/components/DocumentsLayout";
import MongoDbInstances from "../pages/mongo-instance/MongoDbInstances";
import MDLayout from "../pages/md-database/container/MDLayout";
import MDDatabases from "../pages/md-database/MDDatabases";
import DatabaseDetails from "../pages/database-details/DatabaseDetails";

export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        path: "",
        element: <Dashboard />,
        errorElement: <ErrorPage />,
      },
      {
        path: "mongodb-instances",
        element: <MongoDbInstances />,
        errorElement: <ErrorPage />,
      },
      {
        path: "telegram-bots/list",
        element: <TelegramBots />,
        errorElement: <ErrorPage />,
      },
      {
        path: "portainers",
        element: <Portainers />,
        errorElement: <ErrorPage />,
      },
      {
        path: "projects",
        element: <Projects />,
        errorElement: <ErrorPage />,
      },
      {
        path: "projects/create-project",
        element: <CreateProject />,
        errorElement: <ErrorPage />,
      },
      {
        path: "projects/edit/:projectId",
        element: <EditProject />,
        errorElement: <ErrorPage />,
      },
    ],
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "documents/:projectId",
    element: <DocumentsLayout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "", element: <ProjectDocuments />, errorElement: <ErrorPage /> },
      {
        path: "doc/:docId",
        element: <ProjectDocuments />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: "mongodb-instances/databases/:instanceId",
    element: <MDLayout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "", element: <MDDatabases />, errorElement: <ErrorPage /> },
      {
        path: ":dbName",
        element: <DatabaseDetails />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);
