import { useQuery } from "@tanstack/react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";

interface IParams extends TPaging {
  projectId: string;
}

const get = async (params: IParams) => {
  const response: TServerResponse = await request({
    url: apiUrls.document.FETCH_DOCUMENTS,
    method: "GET",
    params: {
      ...params,
    },
  });

  return response;
};

export const useDocumentsQuery = (params: IParams) => {
  return useQuery({
    queryKey: ["admin", "documents", params.projectId],
    queryFn: () => get(params),
  });
};
