import React, { useMemo, useState } from "react";
import ContentBlock from "../../components/ContentBlock/ContentBlock";
import DefaultTable from "../../components/table/DefaultTable";
import { COLUMNS } from "../../columns";

import { CONSTANTS } from "../../constants";
import ActionButtons from "./components/ActionButtons";

import { useTelegramBotsQuery } from "../../hooks/telegram-bots/query/useTelegramBots.query";
import EditBotModal from "./components/EditBotModal";

const TelegramBots = () => {
  const tableColumns = [...COLUMNS.BOTS];
  const [pagedData, setPagedData] = useState({ total: 0 });
  const [page, setPage] = useState(1);

  const [botModal, setBotModal] = useState<{
    opened: boolean;
    data: TTelegramBot | undefined;
  }>({
    opened: false,
    data: undefined,
  });

  const { data, isLoading, refetch } = useTelegramBotsQuery({
    itemPerPage: CONSTANTS.PAGE_LIMIT,
    page,
  });

  tableColumns.push({
    key: "actions",
    label: "Actions",
    renderCell: (data) => (
      <ActionButtons
        data={data}
        handleEditClick={() =>
          setBotModal({
            data: data,
            opened: true,
          })
        }
        refetch={refetch}
      />
    ),
  });

  const tableData: TDatabase[] = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      data.pageData && setPagedData(data.pageData);
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <ContentBlock loading={isLoading} title="Telegram Bots">
      <DefaultTable
        columns={tableColumns}
        data={tableData}
        isLoading={false}
        paginationProps={{ totalPages: pagedData.total, setPage: setPage }}
      />
      {botModal.opened && botModal.data && (
        <EditBotModal
          refetch={refetch}
          data={botModal.data}
          show={botModal.opened}
          closeModal={() => {
            setBotModal({
              data: undefined,
              opened: false,
            });
          }}
        />
      )}
    </ContentBlock>
  );
};

export default TelegramBots;
