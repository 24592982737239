import {
  IconBrandDocker,
  IconBrandMongodb,
  IconFolders,
  IconHome,
  IconList,
  IconPlus,
  IconRobot,
} from "@tabler/icons-react";
import { MainLinkProps } from "./MainLinks";
import { store } from "../store";
import { showDrawer } from "../store/reducers/action-drawer/action-drawer-reducer";

const iconSize = 18;

export const naves: MainLinkProps[] = [
  {
    icon: <IconHome size={iconSize} />,
    label: "Dashboard",
    to: "/",
  },
  {
    icon: <IconBrandMongodb size={iconSize} />,
    label: "MongoDb",
    to: "/mongodb-instances",
    children: [
      {
        icon: <IconPlus size={iconSize} />,
        label: "Add Instance",
        to: "/add",
        onLinkClick: () =>
          store.dispatch(showDrawer({ action: "ADD_DB_INSTANCE" })),
      },
      {
        icon: <IconList size={iconSize} />,
        label: "Instances",
        to: "",
      },
    ],
  },
  {
    icon: <IconRobot size={iconSize} />,
    label: "Telegram Bots",
    to: "/telegram-bots",
    children: [
      {
        icon: <IconPlus size={iconSize} />,
        label: "Add Bot",
        to: "/add-bot",
        onLinkClick: () =>
          store.dispatch(showDrawer({ action: "ADD_TELEGRAM_BOT" })),
      },
      {
        icon: <IconList size={iconSize} />,
        label: "Bots",
        to: "/list",
      },
    ],
  },
  {
    icon: <IconFolders size={iconSize} />,
    label: "Projects",
    to: "/projects",
    children: [
      {
        icon: <IconPlus size={iconSize} />,
        label: "Add Project",
        to: "/create-Project",
      },
      {
        icon: <IconList size={iconSize} />,
        label: "Projects",
        to: "",
      },
    ],
  },
  {
    icon: <IconBrandDocker size={iconSize} />,
    label: "Portainers",
    to: "/portainers",
    children: [
      {
        icon: <IconPlus size={iconSize} />,
        label: "Add Portainer",
        to: "/add-portainer",
        onLinkClick: () =>
          store.dispatch(showDrawer({ action: "ADD_PORTAINER" })),
      },
      {
        icon: <IconList size={iconSize} />,
        label: "Portainers",
        to: "",
      },
    ],
  },
];
