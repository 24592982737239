import { Box, createStyles } from "@mantine/core";
import React, { FC, memo, useMemo } from "react";

interface IStatusChip {
  status: TProject["status"];
}

const StatusChip: FC<IStatusChip> = ({ status }) => {
  const { classes } = useStyles();

  const variant = useMemo(() => {
    switch (status) {
      case "new":
        return classes[".chip-primary"];
      case "closed":
        return classes[".chip-danger"];
      case "open":
        return classes[".chip-success"];
      case "cancelled":
        return classes[".chip-danger"];
      case "waiting":
        return classes[".chip-warning"];
    }
  }, [status, classes]);
  return <Box className={`${classes.root} ${variant}`}>{status}</Box>;
};

const useStyles = createStyles({
  root: {
    padding: "4px 10px",
    borderRadius: "16px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
  },
  ".chip-success": { background: "#cdffe0", color: "#00592e" },
  ".chip-danger": { background: "#ffebeb", color: "#a50000" },
  ".chip-warning": { background: "#fffedc", color: "#764400" },
  ".chip-primary": { background: "#e9f5ff", color: "#004f95" },
  ".chip-purple": { background: "#eddbfa", color: "#5200a5" },
  ".chip-pink": { background: "#ffeffa", color: "#990067" },
  ".chip-orange": { background: "#fff2e4", color: "#8d3600" },
  ".chip-teal": { background: "#e4fdff", color: "#00526e" },
});

export default memo(StatusChip);
