import { useQuery } from "@tanstack/react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";

interface IParams {
  projectId: string;
}

const get = async (params: IParams) => {
  const response: TServerResponse = await request({
    url: apiUrls.project.GET_PROJECT_DETAILS,
    method: "GET",
    params: {
      ...params,
    },
  });

  return response;
};

export const useProjectDetailsQuery = (params: IParams) => {
  return useQuery({
    queryKey: ["admin", "projects/get-details", params],
    queryFn: () => get(params),
  });
};
