import { Box, Button, Grid, TextInput } from "@mantine/core";
import React, { memo } from "react";
import DrawerHeader from "../components/DrawerHeader";
import { useForm, yupResolver } from "@mantine/form";
import { initialValues } from "../../../initial-values";
import { validations } from "../../../validations";

import { showNotification } from "@mantine/notifications";
import { useAppDispatch } from "../../../store/hooks";
import { toggleDrawer } from "../../../store/reducers/action-drawer/action-drawer-reducer";
import { useCreatePortainerMutation } from "../../../hooks/portainer/mutation/useCreatePortainer.mutation";

const AddPortainer = () => {
  const dispatch = useAppDispatch();
  const { isPending, mutateAsync, reset } = useCreatePortainerMutation();

  const { getInputProps, onSubmit } = useForm({
    initialValues: initialValues.portainer,
    validate: yupResolver(validations.portainer),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleSubmit = async (values: TPortainer) => {
    const res = await mutateAsync(values);
    if (res.status === "success") {
      dispatch(toggleDrawer());
      reset();
      showNotification({
        message: res.message,
        color: "green",
      });
    } else {
      showNotification({
        message: res.data.message,
        color: "red",
      });
    }
  };
  return (
    <Box>
      <DrawerHeader title="Add Portainer" />

      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid my={10} gutter={10}>
          <Grid.Col lg={12}>
            <TextInput {...getInputProps("name")} label="Portainer Name" />
          </Grid.Col>
          <Grid.Col lg={12}>
            <TextInput
              {...getInputProps("portainerUrl")}
              label="Portainer URL"
              description="example: - https://portainer.in (do not use '/')"
            />
          </Grid.Col>
          <Grid.Col lg={12}>
            <TextInput
              {...getInputProps("portainerKey")}
              label="Portainer Key"
            />
          </Grid.Col>
          <Grid.Col lg={12}>
            <Button
              loading={isPending}
              mt={10}
              type="submit"
              disabled={isPending}
              size="xs"
            >
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Box>
  );
};

export default memo(AddPortainer);
