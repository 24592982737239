import { useMutation } from "@tanstack/react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";

const add = async (data: {
  name: string;
  instanceId: string;
  collectionName: string;
}) => {
  const response: TServerResponse = await request({
    url: apiUrls.database.ADD_MD_DATABASES,
    method: "POST",
    data,
  });
  return response;
};

export const useAddDbMutation = () => {
  return useMutation({
    mutationFn: add,
  });
};
