import { useQuery } from "@tanstack/react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";

const get = async (params: TPaging) => {
  const response: TServerResponse = await request({
    url: apiUrls.telegramBots.FETCH_BOTS,
    method: "GET",
    params: {
      ...params,
    },
  });

  return response;
};

export const useTelegramBotsQuery = (params: TPaging) => {
  return useQuery({
    queryKey: ["admin", "telegram-bots"],
    queryFn: () => get(params),
  });
};
