import React, { useMemo, useRef, useState } from "react";
import ContentBlock from "../../components/ContentBlock/ContentBlock";
import TextEditor, {
  ITextEditorRef,
} from "../../components/text-editor/TextEditor";
import { ActionIcon, Flex, Text } from "@mantine/core";
import { IconDeviceFloppy, IconEdit } from "@tabler/icons-react";
import { useParams } from "react-router-dom";
import { useDocumentDetailsQuery } from "../../hooks/documents/query/useDocumentDetails.query";
import { useUpdateDocumentMutation } from "../../hooks/documents/mutation/useUpdateDocument.mutation";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { initialValues } from "../../initial-values";

const ProjectDocuments = () => {
  const editorRef = useRef<ITextEditorRef>(null);
  const [editable, setEditable] = useState(false);

  const { docId } = useParams<{
    projectId: string;
    docId: string;
  }>();

  const { data, isLoading, isFetching } = useDocumentDetailsQuery({
    _id: docId ?? "",
  });

  const { onSubmit, values, setFieldValue, setValues } = useForm({
    initialValues: initialValues.document,
  });

  const document: TDocuments = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      setValues(data.data);
      return data.data;
    } else {
      return undefined;
    }
  }, [isLoading, data, setValues]);

  const { isPending, mutateAsync } = useUpdateDocumentMutation();

  const handleSubmit = async (values: TDocuments) => {
    const res = await mutateAsync(values);
    if (res.status === "success") {
      showNotification({
        message: res.message,
        color: "green",
      });
    } else {
      showNotification({
        message: res.data.message,
        color: "red",
      });
    }
  };

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <ContentBlock
        showBack
        backEventPath="/projects"
        loading={isFetching}
        title={document ? document.title : "Documents"}
        rightComponent={
          <ActionIcon
            disabled={isPending}
            loading={isPending}
            color="cyan"
            type={editable ? "button" : "submit"}
            onClick={() => {
              setEditable((state) => !state);
              editorRef.current?.toggleEditable();
            }}
          >
            {editable ? <IconDeviceFloppy /> : <IconEdit />}
          </ActionIcon>
        }
      >
        {document && (
          <TextEditor
            minHeight={450}
            ref={editorRef}
            content={values.description}
            label=""
            onChange={(e) => setFieldValue("description", e)}
            disabled={editable}
          />
        )}

        {!document && (
          <Flex
            mih={"70vh"}
            justify={"center"}
            align={"center"}
            direction={"column"}
          >
            <Text>
              No Document found <br />
            </Text>
            <Text size={"sm"}>Click "+ Add" to Create Document</Text>
          </Flex>
        )}
      </ContentBlock>
    </form>
  );
};

export default ProjectDocuments;
