import React, { useMemo, useState } from "react";
import ContentBlock from "../../components/ContentBlock/ContentBlock";
import DefaultTable from "../../components/table/DefaultTable";
import { COLUMNS } from "../../columns";

import { CONSTANTS } from "../../constants";
import ActionButtons from "./components/ActionButtons";
import EditDbInstanceModal from "./components/EditDbInstanceModal";
import { useDBInstancesQuery } from "../../hooks/md-instance/query/useDBInstances.query";

const MongoDbInstances = () => {
  const tableColumns = [...COLUMNS.INSTANCES];
  const [pagedData, setPagedData] = useState({ total: 0 });
  const [page, setPage] = useState(1);

  const [dbModal, setDbModal] = useState<{
    opened: boolean;
    data: TDatabase | undefined;
  }>({
    opened: false,
    data: undefined,
  });

  const { data, isLoading, refetch } = useDBInstancesQuery({
    itemPerPage: CONSTANTS.PAGE_LIMIT,
    page,
  });

  tableColumns.push({
    key: "actions",
    label: "Actions",
    renderCell: (data) => (
      <ActionButtons
        data={data}
        handleEditClick={() =>
          setDbModal({
            data: data,
            opened: true,
          })
        }
        refetch={refetch}
      />
    ),
  });

  const tableData: TDatabase[] = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      data.pageData && setPagedData(data.pageData);
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <ContentBlock loading={isLoading} title="MongoDB Instance">
      <DefaultTable
        columns={tableColumns}
        data={tableData}
        isLoading={false}
        paginationProps={{ totalPages: pagedData.total, setPage: setPage }}
      />
      {dbModal.opened && dbModal.data && (
        <EditDbInstanceModal
          refetch={refetch}
          data={dbModal.data}
          show={dbModal.opened}
          closeModal={() => {
            setDbModal({
              data: undefined,
              opened: false,
            });
          }}
        />
      )}
    </ContentBlock>
  );
};

export default MongoDbInstances;
