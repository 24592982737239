import { ActionIcon, Group, Text } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import React, { FC, memo } from "react";

import { showNotification } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import { useRemovePortainerMutation } from "../../../hooks/portainer/mutation/useRemovePortainer.mutation";

interface IActionButtons {
  data: TDatabase;
  handleEditClick: () => void;
  refetch: () => void;
}

const ActionButtons: FC<IActionButtons> = ({
  data,
  handleEditClick,
  refetch,
}) => {
  const { mutateAsync, isPending } = useRemovePortainerMutation();

  const handleDelete = async () => {
    const res = await mutateAsync({ _id: data._id });
    if (res.status === "success") {
      refetch();
      showNotification({
        message: res.message,
        color: "green",
      });
    } else {
      showNotification({
        message: res.data.message,
        color: "green",
      });
    }
  };

  const openModal = () =>
    modals.openConfirmModal({
      title: "Confirmation",
      centered: true,
      size: "sm",
      children: <Text size="sm">Are you sure to delete this Portainer ?</Text>,
      labels: { confirm: "Delete", cancel: "Cancel" },
      onConfirm: handleDelete,
      confirmProps: { styles: { root: { background: "#fa5252" } } },
    });
  return (
    <Group spacing={5}>
      <ActionIcon onClick={handleEditClick} size={"sm"} color="cyan">
        <IconEdit />
      </ActionIcon>
      <ActionIcon
        disabled={isPending}
        loading={isPending}
        onClick={openModal}
        size={"sm"}
        color="red"
      >
        <IconTrash />
      </ActionIcon>
    </Group>
  );
};

export default memo(ActionButtons);
