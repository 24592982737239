import { memo, ReactNode } from "react";
import { Box, createStyles, Flex, Table } from "@mantine/core";

import { COLORS } from "../../colors";
import TableLoading from "./components/TableLoading";
import TableSearch from "./components/TableSearch";
import { TPaging } from "../../types/table/tablePaging";
import Pagination from "../Pagination/Pagination";

interface IProps {
  search?: boolean;
  onChangeSearch?: (value: string) => void;
  rightComponent?: ReactNode;
  data: any[];
  columns: TTableColumns[];
  isLoading: boolean;
  paginationProps?: TPaging;
}

const DefaultTable: React.FC<IProps> = ({
  data,
  columns,
  isLoading,
  paginationProps,
  onChangeSearch,
  rightComponent,
}) => {
  const { classes } = styles();

  return (
    <>
      <Flex justify={"space-between"}>
        {onChangeSearch ? (
          <TableSearch onChangeText={onChangeSearch} />
        ) : (
          <div />
        )}
        {rightComponent ? <Box>{rightComponent}</Box> : <div />}
      </Flex>
      <Box>
        {!isLoading ? (
          <Box className={classes.tableContainer}>
            <Table
              withBorder={false}
              verticalSpacing={"md"}
              // striped
              style={{ textAlign: "center", tableLayout: "auto" }}
              width={"100%"}
            >
              <thead>
                <tr>
                  {columns.map((item, index) => {
                    return (
                      <th
                        key={`${"_" + index}`}
                        className={classes.tHead}
                        style={{
                          textAlign: "center",
                          width: "15%",
                          minWidth: item.minWidth ?? "fit-content",
                        }}
                      >
                        {item.label}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data.length === 0 && (
                  <tr style={{}}>
                    <td
                      colSpan={columns.length}
                      style={{ textAlign: "center", fontWeight: 500 }}
                    >
                      No Data Found
                    </td>
                  </tr>
                )}
                {data.length > 0 &&
                  data.map((item: any, index) => {
                    return (
                      <tr
                        key={item._id}
                        style={{
                          backgroundColor:
                            index % 2 ? COLORS.white : COLORS.grey,
                        }}
                      >
                        {columns.map((column, i) => {
                          return (
                            <td
                              style={{
                                width: column.minWidth,
                                whiteSpace: column.minWidth
                                  ? "normal"
                                  : "nowrap",
                              }}
                              key={`${"_" + i}`}
                              className={classes.tBody}
                            >
                              {column.renderCell ? (
                                <Flex justify={"center"}>
                                  {column.renderCell(item)}
                                </Flex>
                              ) : (
                                item[column.key]
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Box>
        ) : (
          <TableLoading />
        )}
        {paginationProps && (
          <Box pb={16} px={32}>
            <Pagination
              setPage={paginationProps.setPage}
              totalPages={paginationProps.totalPages}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default memo(DefaultTable);

const styles = createStyles({
  root: {
    border: "1px solid ",
    borderRadius: 5,
    borderColor: COLORS.borderPrimary,
  },
  header: {
    padding: "20px 32px",
    borderBottom: "1px solid",
    borderColor: COLORS.borderPrimary,
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
  tableContainer: {
    // padding: 18,
    overflow: "auto",
  },
  tHead: {
    fontWeight: 600,
    fontSize: "14px !important",
    color: "#000 !important",
    textAlign: "center",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  tBody: {
    fontSize: "12px !important",
    fontWeight: 400,
    color: "#000",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "& a": {
      fontSize: "14px !important",
      fontWeight: 400,

      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
});
