import { AppShell, Paper, useMantineTheme } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useIsAuthenticated } from "react-auth-kit";
import { Outlet, useNavigate } from "react-router-dom";
import TheHeader from "../../../container/TheHeader";
import MDSidebar from "./MDSidebar";

const MDLayout = () => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login", { replace: true });
    }
  }, [navigate, isAuthenticated]);

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={<MDSidebar opened={opened} />}
      header={<TheHeader opened setOpened={setOpened} />}
    >
      <Paper sx={{ height: "100%" }} p="md">
        <Outlet />
      </Paper>
    </AppShell>
  );
};

export default MDLayout;
