import { adminPrefix } from "../../constants";

export const databaseApis = {
  FETCH_DB_INSTANCES: adminPrefix + "mongodb-instances",
  CREATE_DB_INSTANCE: adminPrefix + "mongodb-instances/create",
  UPDATE_DB_INSTANCE: adminPrefix + "mongodb-instances/update",
  REMOVE_DB_INSTANCE: adminPrefix + "mongodb-instances/remove",
  FETCH_MD_DATABASES: adminPrefix + "mongodb-instances/db-lists",
  ADD_MD_DATABASES: adminPrefix + "mongodb-instances/add-db",
  FETCH_DB_USERS: adminPrefix + "mongodb-instances/get-db-users",
  FETCH_DB_COLLECTIONS: adminPrefix + "mongodb-instances/fetch-db-collections",
  CREATE_DB_USER: adminPrefix + "mongodb-instances/add-user",
};
