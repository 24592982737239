import { useQuery } from "@tanstack/react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";

const get = async (params: { _id: string }) => {
  const response: TServerResponse = await request({
    url: apiUrls.database.FETCH_MD_DATABASES,
    method: "GET",
    params: {
      ...params,
    },
  });

  return response;
};

export const useFetchMDDatabasesQuery = (params: { _id: string }) => {
  return useQuery({
    queryKey: ["admin", apiUrls.database.FETCH_MD_DATABASES, params],
    queryFn: () => get(params),
  });
};
