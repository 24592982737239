import { useMutation } from "@tanstack/react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";

const create = async (data: TDatabase) => {
  const response: TServerResponse = await request({
    url: apiUrls.database.UPDATE_DB_INSTANCE,
    method: "POST",
    data,
  });
  return response;
};

export const useEditDBInstanceMutation = () => {
  return useMutation({
    mutationFn: create,
  });
};
