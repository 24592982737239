export interface ILoginFormValues {
  username: string;
  password: string;
}

export const loginInitialValues: ILoginFormValues = {
  username:
    process.env.NODE_ENV === "development" ? "admin@msInternal.com" : "",
  password: process.env.NODE_ENV === "development" ? "12345678" : "",
};
