import { Box } from "@mantine/core";

export const dbUsersColumns: TTableColumns[] = [
  { key: "user", label: "User" },
  {
    key: "roles",
    label: "Roles",
    renderCell: (row: TDBUsers) => {
      return (
        <Box>
          {row.roles
            .map((role) => role.role)
            .toString()
            .replaceAll(",", ", ")}
        </Box>
      );
    },
  },
];
