import React, { useEffect, useRef } from "react";
import ContentBlock from "../../../../components/ContentBlock/ContentBlock";
import { Button, Grid, Select, Text, TextInput } from "@mantine/core";
import TextEditor, {
  ITextEditorRef,
} from "../../../../components/text-editor/TextEditor";
import FileField from "../../../../components/file-field/FileField";
import { useForm, yupResolver } from "@mantine/form";
import { initialValues } from "../../../../initial-values";
import { validations } from "../../../../validations";
import { DateInput } from "@mantine/dates";
import { PROJECT_STATUS } from "../../../../constants";
import moment from "moment";

import { showNotification } from "@mantine/notifications";
import { useParams } from "react-router-dom";
import { useProjectDetailsQuery } from "../../../../hooks/projects/query/useProjectDetails.query";
import { useUpdateProjectMutation } from "../../../../hooks/projects/mutation/useUpdateProject.mutation";

const EditProject = () => {
  const { isPending, mutateAsync } = useUpdateProjectMutation();
  const editorRef = useRef<ITextEditorRef>(null);

  const { projectId } = useParams<{ projectId: string }>();

  const { data, isLoading } = useProjectDetailsQuery({
    projectId: projectId ?? "",
  });

  const { onSubmit, getInputProps, setValues, values, errors } = useForm({
    initialValues: { ...initialValues.project },
    validate: yupResolver(validations.project),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (!isLoading && data?.status === "success") {
      const projectDetails: TProject = data.data;
      setValues({
        name: projectDetails.name,
        projectDetails: projectDetails.projectDetails,
        attachments: projectDetails.attachments,
        endDate: new Date(projectDetails.endDate ?? ""),
        startDate: new Date(projectDetails.startDate ?? ""),
        status: projectDetails.status,
        _id: projectDetails._id,
      });
    } else {
      setValues(initialValues.project);
    }
  }, [isLoading, data, setValues]);

  const handleSubmit = async (values: TProject) => {
    const formData = new FormData();
    formData.append("_id", values._id ?? "");
    formData.append("name", values.name);
    formData.append("status", values.status);
    formData.append("projectDetails", values.projectDetails);
    values.attachments.forEach((file: File) => {
      formData.append("attachments", file);
    });
    formData.append("endDate", values.endDate?.toISOString() ?? "");
    formData.append("startDate", values.startDate?.toISOString() ?? "");

    const res = await mutateAsync(formData);
    if (res.status === "success") {
      showNotification({
        message: res.message,
        color: "green",
      });
    } else {
      showNotification({
        message: res.data.message,
        color: "red",
      });
    }
  };

  return (
    <ContentBlock loading={isLoading} title="Edit project">
      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid p={15} gutter={25}>
          <Grid.Col lg={12}>
            <TextInput label="Project Name" {...getInputProps("name")} />
          </Grid.Col>
          <Grid.Col lg={4}>
            <DateInput
              label="Start Date"
              {...getInputProps("startDate")}
              minDate={new Date()}
            />
          </Grid.Col>
          <Grid.Col lg={4}>
            <DateInput
              label="End Date"
              {...getInputProps("endDate")}
              minDate={
                moment(values.startDate).add(1, "day").toDate() ?? new Date()
              }
            />
          </Grid.Col>
          <Grid.Col lg={4}>
            <Select
              data={PROJECT_STATUS}
              label="Status"
              {...getInputProps("status")}
            />
          </Grid.Col>
          <Grid.Col lg={12}>
            {values.projectDetails && (
              <TextEditor
                ref={editorRef}
                content={values.projectDetails}
                onChange={(e) => setValues({ projectDetails: e })}
                label="Project Details"
              />
            )}
            {errors.projectDetails && (
              <Text mt={5} size={"xs"} color="red">
                {errors.projectDetails}
              </Text>
            )}
          </Grid.Col>
          <Grid.Col lg={12}>
            <FileField
              files={values.attachments}
              label="Attachments"
              onChange={(files) => setValues({ attachments: files })}
            />
          </Grid.Col>
          <Grid.Col lg={12}>
            <Button
              loading={isPending}
              disabled={isPending}
              type="submit"
              color="cyan"
              miw={150}
            >
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </ContentBlock>
  );
};

export default EditProject;
