import { useQuery } from "@tanstack/react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";

const get = async (params: TPaging) => {
  const response: TServerResponse = await request({
    url: apiUrls.portainer.FETCH_PORTAINERS,
    method: "GET",
    params: {
      ...params,
    },
  });

  return response;
};

export const usePortainersQuery = (params: TPaging) => {
  return useQuery({
    queryKey: ["admin", "portainers"],
    queryFn: () => get(params),
  });
};
