import React, { memo, ReactNode } from "react";
import { SortableElement } from "react-sortable-hoc";

interface IDraggableItem {
  children: ReactNode | undefined;
  className?: string;
}

const DraggableItem = SortableElement<IDraggableItem>(
  (props: IDraggableItem) => (
    <div className={props.className} style={{ zIndex: 5000 }}>
      {props.children}
    </div>
  )
);

export default memo(DraggableItem);
