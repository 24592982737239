import { botsValidation } from "./bots/bots.validation";
import { addUserValidation } from "./db-user/addUserValidation";
import { addDbValidation } from "./db/addDb.validation";
import { dbValidation } from "./db/db.validation";
import { documentValidation } from "./document/document.validation";
import { portainerValidation } from "./portainer/portainer.validation";
import { projectValidation } from "./projects/projects.validation";

export const validations = {
  db: dbValidation,
  bot: botsValidation,
  project: projectValidation,
  portainer: portainerValidation,
  document: documentValidation,
  addDb: addDbValidation,
  addDbUser: addUserValidation,
};
