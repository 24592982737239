import { Center, Drawer, Title } from "@mantine/core";
import React, { memo, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { toggleDrawer } from "../../store/reducers/action-drawer/action-drawer-reducer";

import AddTelegramBot from "./actions/AddTelegramBot";
import AddPortainer from "./actions/AddPortainer";
import AddDBInstance from "./actions/AddDBInstance";

const ActionDrawer = () => {
  const { opened, drawerActions } = useAppSelector(
    (state) => state.actionDrawer
  );
  const dispatch = useAppDispatch();

  const renderDrawer = useMemo(() => {
    switch (drawerActions) {
      case "ADD_DB_INSTANCE":
        return <AddDBInstance />;
      case "ADD_TELEGRAM_BOT":
        return <AddTelegramBot />;
      case "ADD_PORTAINER":
        return <AddPortainer />;
      default:
        return (
          <Center>
            <Title order={4} fw={"bold"}>
              No Action Available
            </Title>
          </Center>
        );
    }
  }, [drawerActions]);

  return (
    <Drawer
      size={"md"}
      withCloseButton={false}
      overlayProps={{ opacity: 0.2, blur: 0 }}
      position="right"
      opened={opened}
      onClose={() => dispatch(toggleDrawer())}
    >
      {renderDrawer}
    </Drawer>
  );
};

export default memo(ActionDrawer);
