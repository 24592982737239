import { Box, FileInput, Group } from "@mantine/core";
import React, { FC, memo } from "react";
import { FileItem } from "./components/FileItem";

interface IFileInputComponent {
  onChange: (file: File[] | undefined) => void;
  label: string;
  files: File[] | undefined;
}

const FileField: FC<IFileInputComponent> = ({ label, onChange, files }) => {
  const handleRemove = (index: number) => {
    const newFiles = files?.filter((_, i) => i !== index);
    onChange(newFiles);
  };

  return (
    <Box>
      <FileInput
        label={label}
        multiple
        placeholder="Select Attachment"
        onChange={(e: any) => {
          onChange(e);
        }}
        value={files}
      />

      <Group my={10} spacing={5}>
        {files?.map((file, index) => (
          <FileItem
            key={file.name}
            file={file}
            onRemove={() => handleRemove(index)}
          />
        ))}
      </Group>
    </Box>
  );
};

export default memo(FileField);
