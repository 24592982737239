import React from "react";
import { Navbar, ScrollArea } from "@mantine/core";
import { MainLinks } from "./MainLinks";

interface ISidebar {
  opened: boolean;
}

const TheSidebar = (props: ISidebar) => {
  const { opened } = props;
  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      hidden={!opened}
      width={{ sm: 200, lg: 250 }}
    >
      <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
        <MainLinks />
      </Navbar.Section>
    </Navbar>
  );
};

export default TheSidebar;
