import { useQuery } from "@tanstack/react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";

interface IParams extends TPaging {
  search?: string;
}

const get = async (params: IParams) => {
  const response: TServerResponse = await request({
    url: apiUrls.project.FETCH_PROJECTS,
    method: "GET",
    params: {
      ...params,
    },
  });

  return response;
};

export const useProjectsQuery = (params: IParams) => {
  return useQuery({
    queryKey: ["admin", "projects", params],
    queryFn: () => get(params),
  });
};
