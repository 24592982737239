import {
  Box,
  Flex,
  Text,
  LoadingOverlay,
  ActionIcon,
  Title,
  Center,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  loading: boolean;
  title: string;
  rightComponent?: React.ReactNode;
  children: (() => React.ReactNode) | React.ReactNode;
  showBack?: boolean;
  emptyData?: boolean;
  backEventPath?: string;
};

const ContentBlock = (props: Props) => {
  const {
    title,
    rightComponent,
    children,
    loading,
    showBack = false,
    emptyData,
    backEventPath,
  } = props;
  const navigate = useNavigate();

  return (
    <Flex direction={"column"} sx={{ height: "100%" }}>
      <Flex justify="space-between">
        <Flex align={"center"}>
          {showBack && (
            <ActionIcon
              color="dark"
              onClick={() =>
                backEventPath ? navigate(backEventPath) : navigate(-1)
              }
              mr={10}
            >
              <IconArrowLeft />
            </ActionIcon>
          )}

          <Text fz="xl">{title}</Text>
        </Flex>
        {rightComponent && rightComponent}
      </Flex>
      <Box mt={20} sx={{ position: "relative", height: "100%" }}>
        {loading && <LoadingOverlay top={0} visible={true} overlayBlur={2} />}
        {!loading && typeof children === "function" && children()}
        {!loading && typeof children !== "function" && children}

        {!loading && emptyData && (
          <Center mih={"80vh"}>
            <Title order={2}>No Data Found</Title>
          </Center>
        )}
      </Box>
    </Flex>
  );
};

export default memo(ContentBlock);
