import { Button, Grid, Modal, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React, { FC, memo } from "react";
import { validations } from "../../../validations";
import { useUpdateBotMutation } from "../../../hooks/telegram-bots/mutation/useUpdateBot.mutation";

interface IEditBotModal {
  show: boolean;
  closeModal: () => void;
  data: TTelegramBot;
  refetch: () => void;
}

const EditBotModal: FC<IEditBotModal> = ({
  closeModal,
  data,
  show,
  refetch,
}) => {
  const { isPending, mutateAsync } = useUpdateBotMutation();
  const { getInputProps, onSubmit } = useForm({
    initialValues: data,
    validate: yupResolver(validations.bot),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleSubmit = async (values: TTelegramBot) => {
    const res = await mutateAsync(values);
    if (res.status === "success") {
      refetch();
      closeModal();
      showNotification({
        message: res.message,
        color: "green",
      });
    } else {
      showNotification({
        message: res.data.message,
        color: "red",
      });
    }
  };

  return (
    <Modal
      size={"md"}
      opened={show}
      onClose={closeModal}
      title="Edit Telegram Bot"
    >
      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid my={10} gutter={10}>
          <Grid.Col lg={12}>
            <TextInput {...getInputProps("title")} label="Bot Title" />
          </Grid.Col>
          <Grid.Col lg={12}>
            <TextInput {...getInputProps("botToken")} label="Bot Token" />
          </Grid.Col>
          <Grid.Col lg={12}>
            <TextInput {...getInputProps("chatId")} label="Chat Id" />
          </Grid.Col>
          <Grid.Col lg={12}>
            <Button
              loading={isPending}
              mt={10}
              type="submit"
              disabled={isPending}
            >
              Update
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};

export default memo(EditBotModal);
