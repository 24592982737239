import moment from "moment";
import StatusChip from "../components/status-chip/StatusChip";

export const projectsColumns: TTableColumns[] = [
  { key: "name", label: "Project Name" },
  {
    key: "status",
    label: "Status",
    renderCell: (row) => <StatusChip status={row.status} />,
  },
  {
    key: "startDate",
    label: "Start Date",
    renderCell: (row) => moment(row.startDate).format("ll"),
  },
  {
    key: "endDate",
    label: "End Date",
    renderCell: (row) => moment(row.endDate).format("ll"),
  },
  {
    key: "createdAt",
    label: "Date Added",
    renderCell: (row) => moment(row.createdAt).format("lll"),
  },
];
