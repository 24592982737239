import { BotColumns } from "./bot.columns";
import { dbUsersColumns } from "./db-users.columns";
import { InstancesColumns } from "./mongodb-instances.columns";

import { PortainerColumns } from "./portainers.columns";
import { projectsColumns } from "./projects.columns";

export const COLUMNS = {
  INSTANCES: InstancesColumns,
  BOTS: BotColumns,
  PROJECTS: projectsColumns,
  PORTAINER: PortainerColumns,
  DB_USERS: dbUsersColumns,
};
