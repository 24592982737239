import {
  ActionIcon,
  Burger,
  Flex,
  Header,
  MediaQuery,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { IconLogout } from "@tabler/icons-react";
import React from "react";

import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

interface ITheHeader {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const TheHeader = (props: ITheHeader) => {
  const theme = useMantineTheme();
  const { opened, setOpened } = props;
  const signOut = useSignOut();
  const navigate = useNavigate();

  return (
    <Header height={{ base: 30, md: 55 }} p="md">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Burger
            opened={opened}
            onClick={() => setOpened((o) => !o)}
            size="sm"
            color={theme.colors.gray[6]}
            mr="xl"
          />
        </MediaQuery>

        <Flex align={"center"} justify="space-between" sx={{ width: "100%" }}>
          <Flex align={"center"}>
            <Text onClick={() => navigate("/")} weight={600} size={16}>
              MS Internal
            </Text>
          </Flex>
          <Tooltip label="Logout">
            <ActionIcon
              onClick={() => {
                signOut();
                navigate("/login");
              }}
              size="lg"
              radius="md"
              variant="gradient"
            >
              <IconLogout size={20} />
            </ActionIcon>
          </Tooltip>
        </Flex>
      </div>
    </Header>
  );
};

export default TheHeader;
