import { authApis } from "./auth.api";
import { databaseApis } from "./database.api";
import { documentsApis } from "./documents.api";
import { portainersApis } from "./portainers.api";
import { projectsApis } from "./projects.api";
import { telegramBotApis } from "./telegram-bots.api";

export const apiUrls = {
  ...authApis,
  database: databaseApis,
  telegramBots: telegramBotApis,
  project: projectsApis,
  portainer: portainersApis,
  document: documentsApis,
};
