import React, { useCallback, useEffect, useState } from "react";
import {
  ActionIcon,
  Button,
  Center,
  Navbar,
  Popover,
  ScrollArea,
  Text,
  TextInput,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";

import { useDocumentsQuery } from "../../../hooks/documents/query/useDocuments.query";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateDocumentMutation } from "../../../hooks/documents/mutation/useCreateDocument.mutation";
import { showNotification } from "@mantine/notifications";
import { useForm, yupResolver } from "@mantine/form";
import { initialValues } from "../../../initial-values";
import { validations } from "../../../validations";
import DraggableContainer from "../../../components/draggable/DraggableContainer";
import DraggableItem from "../../../components/draggable/DraggableItem";
import { ReorderArray } from "../../../components/draggable/helper/reorder-array";
import { SortEnd } from "react-sortable-hoc";

import { useUpdateDocumentOrderMutation } from "../../../hooks/documents/mutation/useUpdateDocumentOrder.mutation";
import DocumentListItem from "./DocumentListItem";
interface IDocumentsSidebar {
  opened: boolean;
}

const DocumentsSidebar = (props: IDocumentsSidebar) => {
  const navigate = useNavigate();
  const { opened: drawerOpened } = props;
  const [opened, setOpened] = useState(false);
  const { projectId } = useParams<{ projectId: string }>();
  const [documents, setDocuments] = useState<TDocuments[]>([]);

  const { data, isFetching, refetch } = useDocumentsQuery({
    itemPerPage: 100,
    page: 1,
    projectId: projectId ?? "",
  });

  useEffect(() => {
    if (!isFetching && data) {
      data.data.length > 0 &&
        navigate(`/documents/${projectId}/doc/${data.data[0]._id}`);
      setDocuments(data.data);
    } else {
      setDocuments([]);
    }
  }, [data, isFetching, navigate, projectId]);

  const { mutateAsync, isPending } = useCreateDocumentMutation();

  const { mutateAsync: updateOrdering } = useUpdateDocumentOrderMutation();

  const { onSubmit, reset, getInputProps } = useForm({
    initialValues: initialValues.document,
    validate: yupResolver(validations.document),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleSubmit = async (values: TDocuments) => {
    const res = await mutateAsync({ ...values, projectId: projectId ?? "" });
    if (res.status === "success") {
      refetch();
      reset();
      if (data?.data?._id) {
        navigate(`/documents/${projectId}/doc/${data.data._id}`);
      }
      setOpened(false);
      showNotification({
        message: res.message,
        color: "green",
      });
    } else {
      showNotification({
        message: res.data.message,
        color: "red",
      });
    }
  };

  const onDragEnd = useCallback(
    async (sort: SortEnd) => {
      const items = ReorderArray(documents, sort.oldIndex, sort.newIndex);
      setDocuments(items);
      await updateOrdering({ documents: items });
    },
    [documents, updateOrdering]
  );

  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      hidden={!drawerOpened}
      width={{ sm: 200, lg: 250 }}
    >
      <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
        <DraggableContainer onSortEnd={onDragEnd} useDragHandle>
          {documents.map((document, index) => (
            <DraggableItem index={index} key={document._id}>
              <DocumentListItem document={document} />
            </DraggableItem>
          ))}
          {documents.length === 0 && (
            <Center mih={"80vh"}>
              <Text size={"sm"}>Click "+ Add" to Document</Text>
            </Center>
          )}
        </DraggableContainer>
      </Navbar.Section>
      <Popover
        opened={opened}
        onChange={setOpened}
        width={300}
        trapFocus
        position="bottom"
        withArrow
        shadow="md"
      >
        <Popover.Target>
          <ActionIcon
            onClick={() => setOpened((o) => !o)}
            variant="filled"
            color="blue"
            size={"lg"}
            radius={50}
            style={{ alignSelf: "flex-end", position: "absolute", bottom: 20 }}
          >
            <IconPlus />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown
          sx={(theme) => ({
            background:
              theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
          })}
        >
          <form onSubmit={onSubmit(handleSubmit)}>
            <TextInput
              label="Title"
              placeholder="Enter document title"
              size="xs"
              {...getInputProps("title")}
            />
            <Button
              disabled={isPending}
              loading={isPending}
              my={10}
              size="xs"
              type="submit"
            >
              Create
            </Button>
          </form>
        </Popover.Dropdown>
      </Popover>
    </Navbar>
  );
};

export default DocumentsSidebar;
