import { ActionIcon, NavLink, createStyles } from "@mantine/core";
import { IconFile, IconGridDots } from "@tabler/icons-react";
import React, { memo } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import DraggableHandle from "../../../components/draggable/DraggableHandle";

interface IDocumentListItem {
  document: TDocuments;
}

const DocumentListItem: React.FC<IDocumentListItem> = ({ document }) => {
  const { classes } = styles();
  const navigate = useNavigate();

  const match = useMatch(
    `/documents/${document.projectId}/doc/${document._id}`
  );

  return (
    <NavLink
      active={!!match}
      classNames={{
        root: classes.listItemRoot,
        rightSection: classes.listItemRightSection,
      }}
      onClick={() =>
        navigate(`/documents/${document.projectId}/doc/${document._id}`)
      }
      label={document.title}
      icon={<IconFile size={18} />}
      rightSection={
        <DraggableHandle>
          <ActionIcon size={"xs"}>
            <IconGridDots />
          </ActionIcon>
        </DraggableHandle>
      }
    />
  );
};

export default memo(DocumentListItem);

const styles = createStyles({
  listItemRoot: {
    "&:hover": {
      "& .mantine-1bjmxhb": {
        display: "block",
      },
    },
  },
  listItemRightSection: {
    display: "none",
  },
});
