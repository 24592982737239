import { NavLink } from "@mantine/core";
import { IconFolder } from "@tabler/icons-react";
import React, { memo } from "react";
import { useMatch, useNavigate, useParams } from "react-router-dom";

interface IMDListItem {
  data: TMDDatabases;
}

const MDListItem: React.FC<IMDListItem> = ({ data }) => {
  const navigate = useNavigate();
  const { instanceId } = useParams<{ instanceId: string }>();

  const match = useMatch(
    `mongodb-instances/databases/${instanceId}/${data.name}`
  );

  return (
    <NavLink
      active={!!match}
      onClick={() =>
        navigate(`/mongodb-instances/databases/${instanceId}/${data.name}`)
      }
      label={data.name}
      icon={<IconFolder size={18} />}
    />
  );
};

export default memo(MDListItem);
