import React, { memo, useMemo } from "react";
import {
  Box,
  Center,
  Flex,
  Grid,
  Loader,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import { useFetchDbCollectionsQuery } from "../../../hooks/md-databases/query/useFetchDbCollections.query";
import { useParams } from "react-router-dom";

const DbCollections = () => {
  const { dbName, instanceId } = useParams<{
    dbName: string;
    instanceId: string;
  }>();

  const { data, isLoading } = useFetchDbCollectionsQuery({
    dbName: dbName ?? "",
    mongoInstanceId: instanceId ?? "",
  });

  const collections: TDbCollections[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);
  return (
    <Box>
      {isLoading && (
        <Center mih={"50vh"}>
          <Loader />
        </Center>
      )}
      {!isLoading && collections.length === 0 && (
        <Center mih={"50vh"}>
          <Title order={2}>No Collection Found</Title>
        </Center>
      )}
      <Grid my={20}>
        {!isLoading &&
          collections.map((collection) => (
            <Grid.Col lg={3} key={collection.name}>
              <Paper
                shadow="sm"
                p={10}
                style={{ borderTop: "3px solid green" }}
              >
                <Text size={"sm"} fw={"bold"}>
                  {collection.name}
                </Text>
                <Flex justify={"space-between"}>
                  <Text size={10} fw={"bold"} color="dimmed" my={10}>
                    Documents : {collection.documentCount}
                  </Text>
                  <Text size={10} fw={"bold"} color="dimmed" my={10}>
                    Size On Disk : {collection.sizeOnDisk} kb
                  </Text>
                </Flex>
              </Paper>
            </Grid.Col>
          ))}
      </Grid>
    </Box>
  );
};

export default memo(DbCollections);
