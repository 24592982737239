import { useMutation } from "@tanstack/react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";

const update = async (data: TPortainer) => {
  const response: TServerResponse = await request({
    url: apiUrls.portainer.UPDATE_PORTAINER,
    method: "POST",
    data,
  });
  return response;
};

export const useUpdatePortainerMutation = () => {
  return useMutation({
    mutationFn: update,
  });
};
