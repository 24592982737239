import { useMutation } from "@tanstack/react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";

interface ICreateUser {
  password: string;
  roles: { role: string; db: string }[];
  username: string;
  instanceId: string;
  dbName: string;
}

const add = async (data: ICreateUser) => {
  const response: TServerResponse = await request({
    url: apiUrls.database.CREATE_DB_USER,
    method: "POST",
    data,
  });
  return response;
};

export const useCreateDbUserMutation = () => {
  return useMutation({
    mutationFn: add,
  });
};
