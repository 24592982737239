import { Box } from "@mantine/core";
import React, { memo, ReactNode } from "react";
import { SortableHandle } from "react-sortable-hoc";

interface IDraggableHandle {
  children: ReactNode | undefined;
}

const DraggableHandle = SortableHandle<IDraggableHandle>(
  (props: IDraggableHandle) => {
    return <Box>{props.children}</Box>;
  }
);

export default memo(DraggableHandle);
