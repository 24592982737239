import {
  Button,
  Grid,
  Modal,
  MultiSelect,
  PasswordInput,
  ScrollArea,
  TextInput,
} from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import React, { FC, memo } from "react";
import { initialValues } from "../../../initial-values";
import { validations } from "../../../validations";
import { DB_USERS_PRIVILEGES } from "../../../constants";
import { useCreateDbUserMutation } from "../../../hooks/md-databases/mutation/useCreateDbUser.mutation";
import { useParams } from "react-router-dom";
import { showNotification } from "@mantine/notifications";

interface ICreateDatabaseUser {
  opened: boolean;
  onClose: () => void;
  reloadData: () => void;
}

const CreateDatabaseUser: FC<ICreateDatabaseUser> = ({
  onClose,
  opened,
  reloadData,
}) => {
  const { dbName, instanceId } = useParams<{
    dbName: string;
    instanceId: string;
  }>();

  const { isPending, mutateAsync } = useCreateDbUserMutation();

  const { getInputProps, onSubmit, reset } = useForm({
    initialValues: initialValues.addDbUser,
    validate: yupResolver(validations.addDbUser),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleSubmit = async (values: typeof initialValues.addDbUser) => {
    const roles = values.roles.map((role) => ({
      role: role,
      db: dbName ?? "",
    }));

    const res = await mutateAsync({
      ...values,
      roles: roles,
      instanceId: instanceId ?? "",
      dbName: dbName ?? "",
    });

    if (res.status === "success") {
      reset();
      reloadData();
      onClose();
      showNotification({ message: res.message, color: "green" });
    } else {
      showNotification({ message: res.data.message, color: "red" });
    }
  };
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Create User"
      scrollAreaComponent={ScrollArea.Autosize}
    >
      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid gutter={10}>
          <Grid.Col lg={12}>
            <TextInput label="Username" {...getInputProps("username")} />
          </Grid.Col>
          <Grid.Col lg={12}>
            <PasswordInput label="Password" {...getInputProps("password")} />
          </Grid.Col>
          <Grid.Col lg={12}>
            <MultiSelect
              searchable
              maxDropdownHeight={160}
              data={DB_USERS_PRIVILEGES}
              label="Select Roles"
              {...getInputProps("roles")}
            />
          </Grid.Col>

          <Grid.Col lg={12}>
            <Button loading={isPending} disabled={isPending} type="submit">
              Add User
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};

export default memo(CreateDatabaseUser);
