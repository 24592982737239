import { useMutation } from "@tanstack/react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";

const remove = async (data: Pick<TPortainer, "_id">) => {
  const response: TServerResponse = await request({
    url: apiUrls.portainer.REMOVE_PORTAINER,
    method: "POST",
    data,
  });
  return response;
};

export const useRemovePortainerMutation = () => {
  return useMutation({
    mutationFn: remove,
  });
};
