import { Text } from "@mantine/core";
import ContentBlock from "../../components/ContentBlock/ContentBlock";

const Dashboard = () => {
  return (
    <ContentBlock loading={false} title="Dashboard">
      <Text>Dashboard Access</Text>
    </ContentBlock>
  );
};

export default Dashboard;
