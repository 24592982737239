import { Box } from "@mantine/core";
import { memo, ReactNode } from "react";
import { SortableContainer } from "react-sortable-hoc";

interface IDraggableContainer {
  children: ReactNode | undefined;
}

const DraggableContainer = SortableContainer<IDraggableContainer>(
  (props: IDraggableContainer) => {
    return <Box>{props.children}</Box>;
  }
);

export default memo(DraggableContainer);
